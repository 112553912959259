<template>
  <div class="carousel" @mouseover="pause" @mouseout="resume">
    <transition-group name="slide" tag="div" class="carousel-images">
      <div v-for="(image, index) in images" :key="index" v-show="index === currentIndex" class="carousel-image">
        <img :src="image.src" alt="Carousel Image" />
        <div class="carousel-index">{{ index + 1 }}</div>
      </div>
    </transition-group>
    <div class="carousel-indicators">
      <span v-for="(image, index) in images" :key="index" :class="{ active: index === currentIndex }"
        @click="goToSlide(index)">
        {{ index + 1 }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "JuLunbotu",
  data() {
    return {
      currentIndex: 0,
      interval: null,
      images: [
        { src: require("@/assets/images/43.jpg"), },
        { src: require("@/assets/images/44.jpg"), },
      ],
    }
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length
    },
    goToSlide(index) {
      this.currentIndex = index
    },
    pause() {
      clearInterval(this.interval)
    },
    resume() {
      this.startAutoPlay()
    },
    startAutoPlay() {
      this.interval = setInterval(() => {
        this.nextSlide()
      }, 3000)
    },
  },
  mounted() {
    this.startAutoPlay()
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.carousel-images {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-index {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.carousel-indicators span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.carousel-indicators span.active {
  background-color: #000;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0);
}
</style>
