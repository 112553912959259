<template>
  <header class="navbar">
    <nav class="nav-container">
      <ul class="nav-list">
        <li v-for="(item, index) in navItems" :key="index" class="nav-item" @mouseover="showDropdown(index)"
          @mouseout="hideDropdown(index)">
          <a href="#" class="nav-link">{{ item.name }}</a>
          <div v-if="item.showDropdown" class="dropdown">
            <p>{{ item.dropdownContent }}</p>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'JuNavigator',
  data() {
    return {
      navItems: [
        { name: '首页', dropdownContent: '这是首页的详细信息', showDropdown: false },
        { name: '产品', dropdownContent: '这是产品的详细信息', showDropdown: false },
        { name: '关于我们', dropdownContent: '这是关于我们页面的详细信息', showDropdown: false },
        { name: '指南', dropdownContent: '这是指南的详细信息', showDropdown: false },
        { name: '手册', dropdownContent: '这是手册的详细信息', showDropdown: false }
      ]
    }
  },
  methods: {
    showDropdown(index) {
      this.navItems.forEach((item, i) => {
        item.showDropdown != i === index //隐藏下拉栏
      })
    },
    hideDropdown(index) {
      this.navItems[index].showDropdown = false
    }
  }
}
</script>

<style scoped>
.navbar {
  width: 100%;
  height: 60px;
  background-color: #333;
  position: relative;
  border-radius: 10px;
  color: white;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1200px;
  /* 可以根据需要调整最大宽度 */
}

.nav-item {
  flex: 1;
  text-align: center;
  position: relative;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #444;
  color: white;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dropdown p {
  margin: 0;
  padding: 0 20px;
}
</style>