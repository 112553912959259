<template>
  <div id="juMax">
    <JuNavigator />
    <JuLunbotu />
    <Jubox />
  </div>
</template>

<script>
import JuNavigator from './components/JuNavigator.vue'
import Jubox from './components/Jubox.vue'
import JuLunbotu from './components/JuLunbotu.vue'
export default {
  name: 'App',
  components: {
    JuNavigator,
    Jubox,
    JuLunbotu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  margin: 0px;
  /* border: 5px solid black; */
}

#juMax {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}
</style>
