<template>
  <div class="theme-container">
    <div class="row">
      <div class="section">部分 1</div>
      <div class="section">部分 2</div>
    </div>
    <div class="row">
      <div class="section">部分 3</div>
      <div class="section">部分 4</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JuBox'
}
</script>

<style scoped>
.theme-container {
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.section {
  width: 48%;
  height: 450px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>